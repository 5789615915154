const en = {
  HOME:"HOME",
  ABOUT:"ABOUT US",
  SOLUTION:"SOLUTIONS",
  PROJECTS:"PROJECTS",
  NEWS:"NEWS",
  SHOPS:"SHOPS",
  CONTACT:"CONTACT",
  REQUESTAQUOTE:"Contact Form",
  ADDRESS:"Address",
  Home:"Home",
  PRODUCTS:"PRODUCTS",
  about1 :"EPC, founders with over 20 years of experience spanning various engineering disciplines, is a professional engineering firm. ",
  about2 : "With an experienced team of engineers, we provide innovative and comprehensive solutions for project management, quality and contract management, feasibility studies, basic and detailed design, manufacturing, and commissioning services from project inception to completion. ",
  about3 : "The strength of our company lies in our flexibility to think and act in accordance with customer requirements",
  ABOUTAS:"ABOUT US",
  Companyoverview:"Company overview",
  OurProjects:"Our Projects",
  AllProjects:"All Projects",
  Lookingfor: "To find out more about our products and solutions, please fill out the contact form",
  OurIndustry:"Our Industry does not respect tradition.",
  Itonlyrespect:"It only respect Innovation",
  Latestnews:"Latest news",
  haberler1:"Fusce non faucibus lorem. Cras eu velit id diam cursus tincidunt in ut dui. Quisque quis augue placerat. ",
  haberler2:"Fusce non faucibus lorem. Cras eu velit id diam cursus tincidunt in ut dui. Quisque quis augue placerat. ",
  haberler3:"Fusce non faucibus lorem. Cras eu velit id diam cursus tincidunt in ut dui. Quisque quis augue placerat. ",
  GetinTouch:"Get in Touch",
  OurSolutions:"Our Solutions",
  footerLogobuttom:`With our productivity and trust-based business model, we are steadfastly committed to achieving the highest standards in the industry. We continuously strive to be a preferred company by responding to changing expectations with a fast, proactive, and innovative approach, always aiming for excellence. Without compromising on our quality in every project and collaboration, we endeavor to maximize customer satisfaction. The strong bonds we establish with our partners, employees, and customers are the foundation of EPC Proje.`,
  SEND:"SEND",
  Name:"Name",
  Email:"Email",
  Phone:"Phone",
  Message:"Message",
  Sektor:"Industry",
  sendedMail:"We received your message and will get back to you as soon as possible.",
  WehelpIndustries:"We help Industries",
  InovateandGrow:"Inovate and Grow",
  Youwillbeal:"You will always be up to date with all business and financial news and",
  investmenttips:"investment tips, market predictions, business advice and guides.",
  Learnmore:"Learn more",
  Viewservices:"View services",
  MaterialScience:"Material Science and Engineering",
  ChemicalResearch:"Energy",
  PowerandEnergy:"Metallurgy",
  SEEMORE:"SEE MORE",
  ScienceandEngineering:"Science and Engineering",
  MechanicalEngineering:"Mining",
  PetroleumandGas:"Petroleum and Gas",
  FactureProcess:"İndustrial Facilities",
  Leader:"Fast and Reliable",
  inPower:"Industrial Solutions",
  andAutomotion:"and Automotion",
  Lorem1:"The customer is very important, the customer will be followed by the customer. In fact, it is ecological",
  Mauris:"the children, the vestibule as well, the bed to drink",
  Welcome:"Leave a Mark on the Future with",
  IndustrialBusiness:"Industrial Business",
  toall:"Innovative Approaches",
  youwill:"You will always be aware of all business and financial news and stay informed with investment tips, market predictions, business advice and guides.",
  ContactUs:"Contact Us",
  FactureProcess2:"Engineering and Project Management",
  Enerji:"Energy",
  Maden:"Mining",
  Metallueloji:"Metallurgy",
  HavaAyristirma:"Air Separation",
  Sulfurukasit:"Sulphuric Acid",
  Muhendislik:"Engineering",
  ProjeYonetimi:"Project Management",
  Gubre:"Fertilizer",
  GazTemizleme:"Gas Cleaning",
  AltinLeach:"Gold Leach plant",
  Industrialplant:"Industrial plant",

  enerjitext:`EPC proje provides turnkey services for projects in the energy sector. It encompasses engineering design, specification of technical requirements, integration of various systems, identification of project-specific materials, negotiation and procurement agreements with suppliers, material supply, on-site construction, installation of facilities, physical realization of the project, commissioning, testing of systems, and performance evaluation. Each project is uniquely designed to meet environmental conditions, energy demands, and the specific needs of investors.With this scope; \n
  
  Engineering Phase: 
  
  • Utilization and integration of renewable energy sources (wind, solar, hydroelectric, etc.) \n
  • Design and optimization of fossil fuel-based energy production systems (steam, gas turbines, waste heat boilers, pulverized coal boilers, fluidized bed boilers, biomass-fueled boilers, and auxiliary facilities) \n
  • Capacity planning and system optimization based on energy demands\n
  • Design of electrical grid and infrastructure\n
  • Integration of energy storage systems\n
  • Use of smart grid technologies\n
  • Emission control and environmental sustainability\n
  
  Procurement Phase:
  
  • Procurement of steam and gas turbines, waste heat boilers, pulverized coal boilers, fluidized bed boilers, biomass-fueled boilers, and auxiliary facilities, generators, transformers, panels, and other energy production equipment
  • Supply of high-voltage lines, transmission cables, and energy distribution equipment
  • Procurement of specialized components for renewable energy sources
  
  Supplier Management and Contracts:
  
  • Selection of suitable suppliers for turnkey solutions
  • Preparation and management of contracts
  • Material logistics and supply chain management
  
  Construction Phase:
  
  • Construction, assembly, and commissioning of the electricity generation facility
  • Infrastructure work and management of environmental impacts
  • Safety and quality of the construction process
  • Construction of energy transmission lines and infrastructure
  • Construction and maintenance of high-voltage transmission lines
  • Installation of distribution infrastructure
  • Construction of energy storage facilities`,
  miningtext:`EPC Proje, provides comprehensive services in the mining industry, offering expertise at every stage of projects. We can detail the main focus areas and services that EPC Proje,  provides in mining projects:

  Mine Site Development:
  
  • Comprehensive engineering design considering geographical and geological features
  • Determination of ore reserves and selection of optimal mining methods
   • Mining process tests 
  • Evaluation of environmental impacts and development of strategies in line with environmental sustainability principles
  
  Engineering Phase:
  
  • Detailed design and construction of facilities
  • Effective management of material procurement, equipment selection, and supply chain
  • Transportation of equipment, organization of material flow, and management of logistical needs for field operations
  
  Ore Preparation and Enrichment:
  
  • Extraction and processing of ores
  • Enrichment processes and obtaining metal concentrates
  
  Environmental Ethics and Sustainability:
  
  • Development of strategies to minimize environmental impacts
  • Planning and implementation of projects in accordance with environmental sustainability principles
  
  Project Management:
  
  • Effective management of project processes
  • Specialization in material procurement, equipment selection, and supply chain management
  • Negotiation of agreements with suppliers, management of collaborations and contracts
  
  EPC Project provides these comprehensive services to the mining industry, focusing on the successful completion of projects, ensuring compliance with industry standards, and offering services for planning and implementing projects in accordance with environmental sustainability principles.`,    

metallurgytext: `EPC Proje, with its Engineering, Procurement, and Construction (EPC) model, provides services in the metallurgy sector, ensuring the effective management and implementation of processes related to the production, processing, and utilization of metals in compliance with industry standards and technological innovations. The main focus areas of EPC Project in metallurgy projects and the services offered include:

Engineering Phase:

• Design and optimization of metallurgical processes
• Engineering details and specifications of metallurgical facilities
• Smelting of metal ores and creation of alloys with desired properties
• Casting processes and molding techniques
• Metal processing and completion of the manufacturing process
• Shaping of raw metal through rolling, pressing, etc.
• Obtaining final products and quality control processes
• Energy efficiency and the use of sustainable energy sources
• Control of project costs and budget management
• Emission control and environmental sustainability
• Compliance with occupational health and safety standards

Procurement Phase:

• Identification of raw materials, equipment, and services required for metallurgy
• Contracting with suppliers and procurement of materials

Construction Phase:

• Physical realization and construction of metallurgical facilities
• Management, supervision, and safety of the construction process`,

havaayristirmatext:`Air Separation Plant
The EPC project ensures the integration of processes for cryogenic (liquid and gaseous oxygen, nitrogen, and argon production) and VPSA (Vacuum Pressure Swing Adsorption) air separation plants, effectively providing services through the Engineering, Procurement, and Construction (EPC) model.

Engineering Phase:

• Technology selection
• Engineering studies
• Project planning
• Cost estimates and budget formation

Procurement Phase:

• Supply of main air compressors, booster compressors, turboexpanders, cold boxes, storage tanks, auxiliary systems, and electrical and automation systems
• Supplier selection, contract negotiation, and material logistics

Construction Phase and Commissioning:

• Construction, assembly, and commissioning of the air separation plant
• Planning, management, and safety of the assembly process
`, 
sulfirikasittext:`Sulfuric Acid Plant;

The Engineering, Procurement, and Construction (EPC) model for sulfuric acid plants ensures the integrity of processes by passing through systems involving the oxidation of sulfur dioxide (SO2) in waste gases to sulfur trioxide (SO3) and its absorption, ultimately resulting in the production of sulfuric acid (H2SO4). Effectively providing services, this model encompasses:

Engineering Phase:

• Technology selection
• Engineering studies
• Project planning
• Cost estimates and budget formation

Procurement Phase:

• Procurement of Drying Tower and Blower Section, Converter group, absorber, auxiliary systems, and the supply of electrical and automation systems
• Supplier selection, contract negotiation, and material logistics

Construction Phase and Commissioning:

• Construction, assembly, and commissioning of the sulfuric acid plant
• Planning, management, and safety of the assembly process
`,
muhendisliktext:`
Front-End Engineering and Design (FEED)

EPC Project aims to provide investors with more information about the project to make critical decisions regarding the design and feasibility of the project, allowing for a better understanding of the feasibility, costs, and risks at this stage. This, in turn, aims to facilitate more effective project management in subsequent stages. The services provided within this scope include;

• Project Identification and Requirements Analysis
• Environmental and Permit Evaluation
• Risk Assessment
• Process Flow Diagram (PFD)
• Laboratory Tests
• Piping and Instrumentation Diagram (P&ID)
• HAZOP, Safety Integrity Level studies
• Unit Layout Studies (3D, 2D)
• Piping and Equipment Designs
• Automation Systems and Instrumentation Designs
• Electrical System Designs
• Multidisciplinary Quantity Takeoff (MTO)
• Cost Estimation
• Creation of Project Schedule and Management Plans

Detail Engineering:

EPC Project aims to achieve effective project management based on detailed design concepts and parameters, building upon the general calculations and designs carried out during the Front-End Engineering and Design. The services provided within this scope include;

Process:

• Process Design
• Process Flow Diagram
• Equipment List
• Heat and Material Balance
• Piping and Instrumentation Diagram

Construction, Building, and Architecture:

• Structural Reinforced Concrete Design
• Structural Steel Design, Calculations, Drawings, and Fabrication Drawings
• Building Architectural Drawings
• Foundation Design, Calculations, and Drawings
• Material List

Piping:

• General Layout Plan
• Piping Design
• 3D Modeling
• Pipe Stress Analysis
• Pipe Support Design
• Piping Drawings (Isometrics)
• Thermal Analysis and Insulation Design

Mechanical:

• Basics of Mechanical Design
• Mechanical Equipment Data Sheets
• Bill of Materials (BOM)
• Equipment Design and Assembly Drawings
• General Layout Drawings
• Thermal Analysis and Insulation Design

Electrical:

• Electrical Design Specification
• Single Line Diagram
• Motor Control Center (MCC) Design
• DCS Control System Design
• PLC Systems
• MCC, PLC Panel Layout Project
• SCADA - Control and Monitoring System
• Electrical Data Sheets
• Lighting Projects
• Grounding Projects
• Material List

Instrumentation and Control:

• Fundamentals of Instrumentation and Control Design
• Instrument/Cable List
• Signal List
• Instrument Data Sheets
• Material List
`,
projeyonetimitext:`EPCM (Engineering, Procurement, and Construction Management) Project Management aims to effectively manage and implement projects between parties throughout the entire process from project initiation to completion, supporting the success of the project. In this context:

Contract Management: 

• Involves effective planning, implementation, and management of contracts between parties throughout the project. This process includes monitoring contract terms, change management, and dispute resolution processes

Planning Management: 

• Involves planning the project process, resource allocation, creating a schedule, and monitoring the progress of the project. The goal is to determine the project schedule, prioritize tasks, and optimize processes

Engineering Management: 

• Involves planning, execution, and management of project engineering design. This process includes coordinating design processes, providing technical solutions, and implementing them by our engineering team

Procurement Management: 

• Involves the effective selection, procurement, and management of materials and equipment to be used in the project. Contract negotiations, supply chain management, and material procurement are part of this process

Construction and Assembly Management: 

• Involves planning, coordination, inspection, and effective management of on-site construction and assembly activities

Quality Management: 

• Aims to ensure that projects progress in accordance with quality standards. This process includes planning, implementing, and monitoring quality control processes

Financial Management: 

• Involves budgeting, cost estimates, tracking expenditures, and managing financial risks

Risk Management: 

• Involves identifying, evaluating, and taking appropriate measures against potential risks
`,
gubretext:`EPC proje, effectively provides services for the planning of chemical fertilizer production facilities Ammonium sulfate, optimizing the processes through granulation techniques for a more efficient distribution of products, and ensuring the integrity of processes through the Engineering, Procurement, and Construction (EPC) model. Within this scope:


Engineering Phase:

• Design, optimization, and engineering details of fertilizer production processes
• Evaluation of the plant's capacity, technical requirements, flexible production capacity based on market demands, and environmental impacts
• Environmentally friendly fertilizer production techniques

Procurement Phase:

• Supply of raw materials, chemicals, and special components
• Supplier selection, contract negotiation, and material logistics

Construction Phase:

• Construction, assembly, and commissioning of the fertilizer production facility
• Planning, management, and safety of the assembly process

Environmental Controls and Emission Management:

• Emission control systems to maintain air and water quality
• Waste management and development of recycling strategies

Technological Innovation and Continuous Improvement:

• Integration of new production technologies and continuous improvement processes
• Smart production processes with sensor technologies and data analytics
• Use of digital platforms for integrated facility management
• Effective utilization of digitization and automation in fertilizer production

Environmental and Health Safety Standards:

• Compliance with regulatory standards related to environmental compliance and emission control.
• Full compliance with occupational health and safety standards.
`,
gaztemizlemetext:`EPC Proje effectively provides services through the model of Engineering, Procurement, and Construction (EPC) to ensure the integrity of processes in order to efficiently clean waste gases and enhance environmental sustainability for these facilities. In this context:

Engineering Phase:

• Gas Cleaning and dust dontrol dystem design and dptimization:
• Analysis of waste gases and determination of cleaning requirements
• Selection and integration of suitable equipment for gas cleaning and dust control systems
• Design of the facility's electrical infrastructure and control systems
• Integration of environmental monitoring and control systems

Procurement Phase:

• Procurement of scrubbers, catalytic converters, electrostatic filter systems, baghouse filter systems, pneumatic conveying systems, emission control devices, sensors, and other cleaning equipment
• Sourcing of high-quality materials and devices
• Material transportation and storage planning
• Management of logistics processes

System Installation and Commissioning Phase:

• Installation and commissioning of these systems
• Planning, management, and safety of the installation process
• Equipment assembly and integration
• Ensuring safety standards during the installation process
• Quality control processes during installation
`,
altinleachtesisitext:`EPC Proje  provides effective services using the Engineering, Procurement, and Construction (EPC) model to ensure the integrity of processes for extracting gold from low-grade gold ores in gold leach plants. Within this scope:

Engineering Phase:

• Engineering studies
• Project planning
• Cost estimates and budget formation

Procurement Phase:

• Supply of crushers, conveyor systems, mills, mixers, tanks, reactors, leach tanks, adsorption columns, precipitation tanks, filter presses, recycling systems, and auxiliary systems, the supply of electrical and automation systems
• Supplier selection, contract negotiation, and material logistics

Construction Phase and Commissioning:

• Construction, assembly, and commissioning of the gold leach plant
• Planning, management, and safety of the assembly process
`,

endustriyeltesisler:`EPC Proje, provide turnkey services for industrial facilities, including engineering, planning, design, construction, operation, and maintenance of the facility. Each project is uniquely designed according to environmental conditions, energy demands, and the needs of investors. Within this scope:

• Facility Planning and Design
• Process Engineering
• Mechanical and Equipment Engineering
• Electrical and Automation Engineering

Engineering in industrial facilities involves the effective management and continuous improvement of complex systems, offering technical expertise and creative solutions in this field.

`,
ENERJI:"Energy",
ENERJItext:"By embracing the principles of efficient energy usage and environmental sustainability, we aim to offer customized solutions to our investors in every project, with the goal of minimizing energy consumption and environmental impact. With our experienced and expert team, we install reliable and high-performance energy systems using the latest technologies.",
MADEN:"Mining",
MADENtext:"As a company specialized in the mining sector, we offer innovative projects and sustainable solutions. Our experienced engineers and expert team work to adapt to the complexity and variability of the global mining industry at every stage to ensure the successful completion of the project.",
Metalurji:"Metallurgy",
Metalurjitext:"By developing customized solutions tailored to the needs of the metallurgy industry, we aim to provide our investors with maximum value. Utilizing the latest technologies and best practices in metallurgy, we offer solutions that provide our investors with a competitive advantage.",
MaterialScienceText:"In our ever-changing and evolving world, we work with our expert team to successfully manage complex projects using innovative approaches. We strive to increase efficiency by optimizing your goals and business processes, ensuring the successful completion of your projects.",
EndustrielTesisler:"Industrial plant",
EndustrielTesislertext:"From the design of industrial facilities to the entire process of project management and commissioning, our experienced engineers and expert teams ensure successful completion at every stage of the project",
hidrometalurji:"Hydrometallurgy",
hidrometalurjiText:"Hydrometallurgy is a method that combines chemical and physical processes to extract metals from metal ores. In the gold leach plant, our experienced engineers provide an environmentally friendly and efficient metal extraction process based on hydrometallurgy, offering innovative solutions in this field.",
muhendislikproje:"Engineering and Project Management",


Contactustoday:"Contact us",
Formoreinformation:"For more information, please contact us. We will respond to your question as quickly as possible. ",
hometext1:"In industrial solutions,",
hometext2:"we  integrate technology with functionality",
hometext3:"",
TamamlananProjeler:"Completed Projects",
enerjibaslik1:"Wartsila Engines and auxiliaries",
enerjiicerik1:`Engine brand  :	Wartsila

Origin :	Finland

Capacity	16,621 MW / each (at sea level) x 7 pcs engine	 

Type	:Natural Gas Fired, 18 V50

Manufacturing year  :2001

Scope  : EPC `,
enerjibaslik2:"Heat Recovery Steam Generators ",
enerjiicerik2:`Brand	: Aalborg ,Finland

Capacity 	7 ton/h, 11,5 bar / each and plus 2 ton/h, 4 bar / each steam 

Quantity	7 pcs. + 7 pcs.

Type	:Natural circulation, one pass, Vertical type

Scope  : EPC 
`,
enerjibaslik3:"DeSOx (Desulphurization)",
enerjiicerik3:`Supplier :Hamon 

Capacity : 650.000 Nm3/h

Scope : EPC  
`,
enerjibaslik4:"DeNOx ( Denitrogen oxides)",
enerjiicerik4:`Supplier : Haldor Topsø 

Capacity : 65000 Nm3/h x 7pcs 

Scope : EPC  
`,
enerjibaslik5:"Siemens 8,5 MW steam turbine and auxiliaries systems",
enerjiicerik5:`Scope : EPC `,
enerjibaslik6:"Nuh Çimento 46 MW natural gas fired power plant ",
enerjiicerik6:`Natural gas turbine : GE LM 6000,

HRSG :IEG 

Scope : EPC  
`,
enerjibaslik7:"Coal Fired Fluidized Bed Boiler, natural gas fired boiler and auxiliary systems",
enerjiicerik7:`Capacity :140 t/h , 46 barg , 460 °C  steam 

Scope : EPC 

`,
metalurjibaslik1:"Aluminum Casting Furnaces",
metalurjiicerik1:`Standard Ingot, T-bars, Flat Ingot, Billet 

6.000 t/y Capacity Aluminum Casting Furnaces

Scope: EPC 
`,
toztutmabaslik1:"Electrostatıc Precıpıtator(ESP) ",
toztutmaicerik1:` Electrostatıc Precıpıtator Flue gas flow 80 000 Nm3/h capacity, Babcock & Wilcox 

Electrostatıc Precıpıtator Flue gas flow 80 000 Nm3/h capacity,, Alstom, 

Electrostatıc Precıpıtator Flue gas flow 70 000 Nm3/h capacity,  Alstom

Electrostatıc Precıpıtator Flue gas flow 95 000 Nm3/h capacity,, Elex

Scope : EPC 
`,
enerjibaslik8:"Samsun  240 Mw Natural Gas Fıred Combıned Cycle Power Plant ",
enerjiicerik8:`The Power Plant mainly consist of :

1- GAS TURBINES & Auxiliaries (2 SETS) ,brand : GE LMS 100
       
GAS TURBINES & Auxiliaries
Brand	: GE
Origin	U.S.A.
Capacity	105 MW / each (at sea level)
Quantity 	2 pcs.	
Type	Naural Gas Fired, LMS100, 
Manufacturing year 	2009

2-HEAT RECOVERY STEAM GENERATORS (2SETS), , 
HEAT RECOVERY STEAM GENERATORS 
Brand	:Aalborg 
Origin	Finland
Capacity 	LP 24 ton/h, 4 bar / each
	HP 120 ton/h, 40 bar / each
Quantity	2 pcs.
Type	Natural circulation, one pass, 
	Vertical type
3-STEAM TURBINE (1 SET) 
Brand :Siemens 
Origin : Germany
Capcity : 40  MW 

4- GAS COMPRESSOR STATION (1SET)

5- HIGH VOLTAGE SWITCHYARD (3 SETS)

6-BOP Equipments
Compressed air station
C&I system and equipments
.LV/MV AC and DC systems
.Cooling SYSTEMS 

Scope : EPC 
`,
enerjibaslik9:"Eti Aluminum Factory Natural Gas Conversions",
enerjiicerik9:`Scope : EPC `,
sulfirbaslik1:"Sulfuric Acid Plant",
sulfirikicerik1:`Flow rate 120.000 Nm3/h ,SO2 off-gas 

98,5 % Sulfuric acid production 1310 t/d 

Emission at Stack - Sulphuric Acid Plant (in kg SO2 per ton of produced Sulphuric Acid) : 2,0kg SO2/t H2SO4

Scope : EPC 
`,
amonyakbaslik1:"Ammonia storage tank",
amonyakicerik1:`15,000 tons of liquid ammonia (99.8%) storage tank,

Design temperature: - 40 °C, Design pressure:- 5/+200 mbarg

Operating temperature: -31.2/-33 °C

Max.boil off rate: 0.04%

Ammonia vapor recovery system, compression system, gas condensation system

Scope: EPC
`,
asitbaslik1:"Sulfuric Acid storage tank",
asiticerik1:`Capacity: 7500 tons x 2 Sulfuric Acid (98% H2SO4) storage tanks

Scope : EPC 
`,
kriyojenikbaslik1:"Cryogenic air separation plant",
kriyojenikicerik1:`Brand : Hangzhou Hangyang

Origin : China

Gas oxygen: 10 000 Nm3/h, 99.6%O2

Liquid oxygen: 500 Nm3/h, 99.6%O2

Gas nitrogen: 9500 Nm3/h, ≤10 ppm O2

Liquid nitrogen: 500 Nm3/h, ≤10 ppm O2

Liquid argon: 350 Nm3/h,≤ 1 ppm O2,≤3 ppm N2

instrument air: 6000 nm3/h, 8 barg, -60 °C dew point

Scope: EPC
`,
amonyumbaslik1:"Ammonium sulphate fertilizer plant",
amonyumicerik1:`Capacity 800 t/day, (NH4)2SO4 Crystal 21% nitrogen content fertilizer

Scope: EPC
`,
metalurjibaslik2:"Anode copper casting plant",
metalurjiicerik2:`Capacity : 100 t/h (50t/h x 2)  Anode copper casting shop 

Scope : EPC 
`,
madenbaslik1:"Siirt Copper Mine Shaft Plant ",
madenicerik1:`Shaft Type : Production Shaft

Hoisting Purpose : Material And Man Riding 

Hosting Supplier  : Siemag Tecberg 

Engineering : Nerin Engineering 

Shaft Diameter: 6,5 M

Depth : 661 M 

Hosting Distance : 629 M 

Hoisting Capacity : 1 500 000 T/Y 
`,
madenbaslik2:"Kırgızistan Gold plant ",
madenicerik2:`Feed capacity  : 1 500 000 t/y

Process type :  Tank Leaching  Plant 

Engineering : Amec Foster Wheeler 
`,
madenbaslik3:"Adıyaman Copper Flotation Plant",
madenicerik3:`Ore feed : 700 000 t/y 

Cu recovery  84,5%

Cu Concentrate: 16,5 %

Copper concentrate capacity : 140 000 t/y

Engineering : Outotec 

Scope :  EPC 
`,
metalurjibaslik3:"Eti bakır Smelting  plant",
metalurjiicerik3:`Engineering completion time: 1 year

Assembly and commissioning completion time: 6 months

Engineering : Outotec (basic engineering )

Scope : EPC 

Copper feeding capacity to flash smelting furnace : 70 t/h

Copper concentrate feeding capacity to dryer   :100 t/h 

Completed plants within the scope of this project

•	Flash Smelter Furnace  cooling system

•	Concentrate storage building including tripper car conveyors , conveyors  and special crane systems 

•	Dryer 

•	Copper feeding systems  mixture tank , loss in weight feeding system 

•	Electrostatic Precipitator (ESP)

•	Pneumatic conveying systems
	 
•	PS (peirce -Smith )converter plant 

•	Anode Casting plant

•	Sulphuric acid plant

•	Cryogenic oxygen plant
`,

powermetertext:` KPM312 Three Phase Multi Channel Power Meter is used for max 4 branch circuit power monitoring and data transmission. It adopts DIN 35mm rail type installation and LCD display and integrates three-phase electrical parameters measurement, 2~31st harmonic analysis and time statistics. KPM312 default communication is Modbus-RTU by RS485. WiFi, 4G, Lora communication are optional. It can also expand 4-way digital input and 4-way relay output, 4-way temperature measurement for each circuit.

Product Features:

•Communication interface protocol

  •RS485 WiFi 4G Lora
  •MODBUS-RTU, MQTT


•Power quality measurement

  •2-31st harmonic measurement analysis+ 
  •Demand vaule, Max/Min vaule statistics
  •Residual current measurement


•4 Digital inputs for 4 circuits

  •Digital input
  •Logic alarm input


•4 Digital outputs for 4 circuits

  •I,U,P Limits output
  •Communication remote control
  •Logic output
  

•Record function

  •Meter working time, load time statics and record
  •3 month monthly average power factor 
  •3 month multi tariff power consumption data record
  
`,
powermeterbaslik:"KPM312 Three Phase Multi Channel Power Meter"
};
export { en };