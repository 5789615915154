import { Link } from 'react-router-dom';
import i18n from '../common/languageConf';
import { useLanguage } from '../common/LanguageContext';
import { useEffect } from 'react';
import GlobalSettingStore from '../Stores/GlobalSettingStore';
import { observer } from 'mobx-react';


function Navigation() {


	useEffect(() => {
	}, [GlobalSettingStore.GlobalSetting])

	return (
		<>
			<section className="main-menu-one finance-navbar">
				<div className="container">
					<div className="row">
						<div className="col-md-9">
							<nav id="main-navigation-wrapper" className="navbar navbar-default">
								<div className="navbar-header">
									<button type="button" data-toggle="collapse" data-target="#main-navigation" aria-expanded="false" className="navbar-toggle collapsed">
										<span className="sr-only">Toggle navigation</span><span className="icon-bar"></span><span className="icon-bar"></span><span className="icon-bar"></span>
									</button>
								</div>
								<div id="main-navigation" className="collapse navbar-collapse">
									<ul className="nav navbar-nav navbar-left">
										<li><Link to="/">{i18n.t("HOME")}</Link>
											{/* <ul className="dropdown-submenu">
													
													<li><a href="index-two.html">Home Two</a></li>
													<li><a href="index-three.html">Home Three</a></li>
													<li><a href="index-four.html">Home Four</a></li>
													
												</ul>  */}
										</li>
										<li><Link to="/about">{i18n.t("ABOUT")}</Link>
											{/* <ul className="dropdown-submenu">
													<li><a href="our_team.html">Our Team</a></li>
													<li><a href="testimonials.html">Testimonials</a></li>
													<li><a href="our_faq.html">Our Faq</a></li>
													
												</ul> */}
										</li>
										<li><Link to="/solution">{i18n.t("SOLUTION")}</Link>
											{/* <ul className="dropdown-submenu">
													<li><a href="Material-Scienceand-Engineering.html">Material Science and Engineering</a></li>
													<li><a href="chemical-research.html">Chemical Research</a></li>
													<li><a href="power-and-energy.html">Power and Energy</a></li>
													<li><a href="Mechanical-Engineering.html">Mechanical Engineering</a></li>
													<li><a href="Petroleum-and-Gas.html">Petroleum and Gas</a></li>
													<li><a href="Facture-Process.html">Facture Process</a></li>
												</ul> */}
										</li>
										<li><Link to="/projects">{i18n.t("PROJECTS")}</Link>
											{/* <ul className="dropdown-submenu">
												<li><a href="projects.html">Devam Eden Projeler</a></li>
												<li><a href="single-project.html">Tamamlanan Projeler</a></li>
											</ul> */}
										</li>
										{/* <li><Link to="/news">{i18n.t("NEWS")}</Link>
												 <ul className="dropdown-submenu">
													<li><a href="news-big.html">News Big View</a></li>
													<li><a href="news-single.html">Single News</a></li>
												</ul> 
											</li> */}
										{/* <li><Link to="/producta">{i18n.t("PRODUCTS")}</Link>
												 <ul className="dropdown-submenu">
													<li><a href="shop-details.html">Product Details</a></li>
													<li><a href="card-page.html">Cart Page</a></li>
													<li><a href="Checkout-Page.html">Checkout Page</a></li>
												</ul> 
											</li> */}

										<li><Link to="/contact">{i18n.t("CONTACT")}</Link></li>
									</ul>
								</div>
							</nav>
						</div>
						<div className="col-md-3">
							<div className="button-wraper pull-right">
								<div className="button">
									<a className="hvr-bounce-to-right" href="/contact">{i18n.t("REQUESTAQUOTE")}</a>
								</div></div>
							<ul className="search">

								<li className="first"><button id="searchDropdown" className="search dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
									<i className="fa fa-search" aria-hidden="true"></i>
								</button>
									<form className="dropdown-menu">
										<input className="form-control" placeholder="Searcs..." type="text" />

									</form>
								</li>

							</ul>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
const NavigationComponent = observer(Navigation);
export default NavigationComponent;
