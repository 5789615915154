const tr = {
  HOME: "ANASAYFA",
  ABOUT: "HAKKIMIZDA",
  SOLUTION: "ÇÖZÜMLER",
  PROJECTS: "PROJELER",
  NEWS: "HABERLER",
  SHOPS: "MAĞAZA",
  CONTACT: "İLETİŞİM",
  REQUESTAQUOTE: "İLETİŞİM FORMU",
  ADDRESS: "Adres",
  Home: "Anasayfa",
  PRODUCTS: "ÜRÜNLER",
  about1: "EPC Proje, farklı mühendislik disiplinlerinden oluşan 20 yılı aşkın deneyime sahip kuruculardan oluşan profesyonel bir mühendislik firmasıdır. ",
  about2: "Deneyimli mühendis kadrosuyla projenin başlangıçtan bitişene kadar proje yönetimi, kalite  ve sözleşme yönetimi ,fizibilite çalışmaları, temel ve detay tasarım, imalat ve devreye alma hizmetlerini yenilikçi  ve bütüncül çözümlerle sunuyoruz. ",
  about3: "Şirketimizin gücü, müşteri gereksinimlerine esneklikle düşünme ve hareket etme yeteneğidir. ",
  ABOUTAS: "Hakkımızda",
  Companyoverview: "Genel Bakış",
  OurProjects: "Projelerimiz",
  AllProjects: "Tüm Projeler",
  Lookingfor: "Ürünlerimiz ve çözümlerimiz hakkında daha fazla bilgi almak için lütfen iletişim formunu doldurunuz. ",
  OurIndustry: "Sektörümüz geleneklere saygı duymuyor.",
  Itonlyrespect: "Sadece İnovasyona Saygı Duyar",
  Latestnews: "Son Haberler",
  haberler1: "Fusce non faucibus lorem. Cras eu velit id diam cursus tincidunt in ut dui. Quisque quis augue placerat. ",
  haberler2: "Fusce non faucibus lorem. Cras eu velit id diam cursus tincidunt in ut dui. Quisque quis augue placerat. ",
  haberler3: "Fusce non faucibus lorem. Cras eu velit id diam cursus tincidunt in ut dui. Quisque quis augue placerat. ",
  GetinTouch: "İletişime Geçin",
  OurSolutions: "Çözümlerimiz",
  footerLogobuttom: `Verimlilik ve güvene dayalı iş modelimizle, sektördeki en yüksek standartları sağlamak için kararlı bir şekilde çalışıyoruz.
  Değişen beklentilere hızlı, proaktif ve yenilikçi bir yaklaşımla yanıt vererek, tercih edilen bir şirket olma hedefimizi her zaman sürdürüyoruz ve daha iyisini hedefliyoruz. Her proje ve işbirliğinde kalitemizden ödün vermeden, müşterilerimizin memnuniyetini en üst düzeye çıkarmak için çaba gösteriyoruz. İş ortaklarımız, çalışanlarımız ve müşterilerimizle kurduğumuz güçlü bağlar, EPC Proje’nin temel dayanaklarıdır.
  `,
  SEND: "GÖNDER",
  Name: "Ad Soyad",
  Email: "Email",
  Phone: "Telefon",
  Message: "Mesaj",
  Sektor: "Sektör",
  sendedMail: "Mesajınızı aldık en kısa sürede dönüş sağlayacağız.",
  WehelpIndustries: "Sektörlere yardımcı oluyoruz",
  InovateandGrow: "İnovasyon Yapın ve Büyüyün",
  Youwillbeal: "Tüm iş ve finans haberlerinden her zaman haberdar olacak ve",
  investmenttips: "yatırım ipuçları, piyasa tahminleri, iş tavsiyeleri ve rehberler.",
  Learnmore: "Daha fazla bilgi edinin",
  Viewservices: "Hizmetleri görüntüle",
  MaterialScience: "Malzeme Bilimi ve Mühendisliği",
  ChemicalResearch: "Enerji",
  PowerandEnergy: "Metalurji",
  SEEMORE: "FAZLASINI GÖR",
  ScienceandEngineering: "Bilim ve Mühendislik",
  MechanicalEngineering: "Madencilik",
  PetroleumandGas: "Petrol ve Gaz",
  FactureProcess: "Endüstriyel Tesisler",
  Leader: "Hızlı ve Güvenilir",
  inPower: "Endüstriyel Çözümler",
  andAutomotion: "lider",
  Lorem1: "Müşteri çok önemli, müşteriyi müşteri takip edecek. Aslında ekolojik",
  Mauris: "çocuklar, giriş holü, içecek yatağı",
  Welcome: "İnovatif",
  IndustrialBusiness: "Endüstriyel İşletme",
  toall: "Yaklaşımlarla Geleceğe İz Bırakın",
  youwill: "Her zaman tüm iş ve finans haberlerinden haberdar olacak ve yatırım ipuçları, piyasa tahminleri, iş tavsiyeleri ve kılavuzlardan haberdar olacaksınız.",
  ContactUs: "Bizimle İletişime Geç",
  FactureProcess2: "Mühendislik ve Proje Yönetimi",
  Enerji: "Enerji",
  Maden: "Maden",
  Metallueloji: "Metalurji",
  HavaAyristirma: "Hava Ayrıştırma",
  Sulfurukasit: "Sülfürik Asit",
  Muhendislik: "Mühendislik",
  ProjeYonetimi: "Proje Yönetimi",
  Gubre: "Gübre",
  GazTemizleme: "Gaz Temizleme",
  AltinLeach: "Altın Leach Tesisi",
  Industrialplant: "Endüstriyel Tesisler",

  enerjitext: `EPC  proje, enerji sektöründeki projelerin mühendislik tasarımı, teknik özelliklerin belirlenmesi, çeşitli sistemlerin entegrasyonu, projeye özgü malzemelerin belirlenmesi, tedarikçilerle anlaşmaların yapılması ve malzeme temini, malzemelerin sahada inşa edilmesi, tesislerin kurulumu ve projenin fiziksel olarak gerçekleştirilmesi, projenin işletmeye alınması, sistemlerin test edilmesi ve performansın değerlendirilmesi gibi anahtar teslim hizmetleri sunmaktadır. Her proje, çevresel koşullara, enerji taleplerine ve yatırımcıların ihtiyaçlarına özgü olarak tasarlanmaktadır. Bu kapsamda ;
  
  Mühendislik Aşaması:
  
  • Yenilenebilir enerji kaynaklarının (rüzgar, güneş, hidroelektrik vb.) kullanımı ve entegrasyonu.
  • Fosil yakıt tabanlı enerji üretim sistemlerinin (buhar , gaz türbinleri,atık ısı kazanları, Pulverize Kömür kazanları , akışkan yataklı kazanlar , biomass yakıtlı kazanlar  ve yardımcı tesisler) tasarımı ve optimizasyonu
  • Kapasite planlaması ve enerji taleplerine göre sistem optimizasyonu
  • Elektrik şebekesi ve alt yapı tasarımı
  • Enerji depolama sistemlerinin entegrasyonu
  • Akıllı şebeke teknolojilerinin kullanımı
  • Emisyon kontrol ve çevresel Sürdürülebilirlik
  
  Satın Alma Aşaması:
  
  • Buhar ve gaz türbinleri , atık ısı kazanları, pulverize kömür kazanları , akışkan yataklı kazanlar , biomass yakıtlı kazanlar  ve yardımcı tesisler, jeneratörler, transformatörler, panolar ve diğer enerji üretimi ekipmanlarının tedariği
  • Yüksek gerilim hatları, transmisyon kabloları ve enerji dağıtım ekipmanlarının temini
  • Yenilenebilir enerji kaynakları için özel bileşenlerin tedariği
  
  Tedarikçi Yönetimi ve Sözleşmeler:
  
  • Anahtar teslimi çözümler için uygun tedarikçi seçimi
  • Sözleşmelerin hazırlanması ve yönetimi
  • Malzeme lojistiği ve tedarik zinciri yönetimi
  
  İnşaat Aşaması:
  
  • Elektrik üretim tesisi inşası ve entegrasyonu, montajı ve devreye alınması.
  • Altyapı çalışmaları ve çevresel etkilerin yönetimi
  • İnşaat sürecinin güvenliği ve kalitesi
  • Enerji hletim hatları ve altyapı inşaatı
  • Yüksek gerilim iletim hatlarının inşası ve bakımı
  • Dağıtım alt yapılarının kurulumu
  • Enerji depolama tesislerinin inşası` ,

  miningtext: ` EPC Proje, madencilik endüstrisinde sunmuş olduğu kapsamlı hizmetler, projelerin her aşamasında uzmanlık sağlamaktadır. EPC Proje'nin madencilik projelerindeki ana odak noktalarını ve sunduğu hizmetleri detaylı bir şekilde açıklayabiliriz:

  Maden Sahası Geliştirme:
  
  • Coğrafi ve jeolojik özellikleri dikkate alarak kapsamlı mühendislik tasarımı.
  • Maden rezervlerinin belirlenmesi ve optimum madencilik yöntemlerinin seçilmesi.
  • Maden proses testleri
  • Çevresel etkilerin değerlendirilmesi ve çevresel sürdürülebilirlik prensiplerine uygun stratejiler geliştirme.
  
  Mühendislik Aşaması:
  
  • Tesislerin detaylı tasarımı ve inşası
  • Malzeme temini, ekipman seçimi ve tedarik zincirinin etkili bir şekilde yönetilmesi
  • Ekipmanların taşınması, malzeme akışının düzenlenmesi ve saha operasyonlarının lojistik ihtiyaçlarının yönetilmesi
  
  Cevher Hazırlığı ve Zenginleştirme
  
  • Cevherlerin madencilikten çıkarılması ve işlenmesi
  • Zenginleştirme süreçleri ve metal konsantrelerinin elde edilmesi
  • Çevresel Etiket ve Sürdürülebilirlik:
  
  • Çevresel etkilerin minimuma indirilmesi için stratejilerin geliştirilmesi
  • Çevresel sürdürülebilirlik prensiplerine uygun olarak projenin planlanması ve uygulanması
  
  Proje Yönetimi:
  
  • Proje süreçlerinin etkili bir şekilde yönetilmesi
  • Malzeme temini, ekipman seçimi ve tedarik zinciri yönetimi konusunda uzmanlaşma
  • Tedarikçilerle anlaşmaların yapılması, işbirlikleri ve sözleşmelerin yönetilmesi
  
  EPC Proje ,madencilik endüstrisine sunduğu bu kapsamlı hizmetler, projelerin başarıyla tamamlanmasını ve endüstri standartlarına uygun olarak faaliyete geçirilmesini ve çevresel sürdürülebilirlik prensiplerine uygun olarak projelerin planlanması ve uygulanması üzerine hizmetler sunmaktadır. `,
  metallurgytext: `EPC Proje , Metalurji sektörü, metallerin üretimi, işlenmesi ve kullanımıyla ilgili süreçlerin etkili bir şekilde yönetilmesi ve uygulanması, Mühendislik, Satın Alma ve İnşaat (EPC) modeli,  yeni süreçlerin ve ekipmanların entegrasyonu endüstri standartlarına ve teknolojik yeniliklere uyumlu hizmet sunmaktadır . EPC Proje'nin metalurji projelerindeki ana odak noktaları ve sunduğumuz hizmetleri kapsamında ;

Mühendislik Aşaması:

• Metalurji süreçlerinin tasarımı ve optimizasyonu
• Metalurjik tesislerin mühendislik detayları ve özellikleri
• Metal cevherlerin ergitilmesi ve istenen özelliklere sahip alaşımların oluşturulması
• Döküm süreçleri ve kalıplama teknikleri
• Metal İşleme ve sürecin tamamlanması 
• Ham metalin şekillendirilmesi, haddeleme, presleme vb.
• Son ürünlerin elde edilmesi ve kalite kontrol süreçleri
• Enerji verimliliği ve sürdürülebilir enerji kaynaklarının kullanımı
• Proje maliyetlerinin kontrolü ve bütçe yönetimi
• Emisyon kontrolü ve çevresel sürdürülebilirlik
• İşçi sağlığı ve güvenliği standartlarına uyum

Satın Alma Aşaması:

• Metalurji için gerekli olan hammaddeler, ekipmanlar ve hizmetlerin belirlenmesi
• Tedarikçilerle sözleşmelerin yapılması ve malzeme temini

İnşaat Aşaması:

• Metalurji tesislerinin fiziksel olarak gerçekleştirilmesi ve inşa edilmesi
• İnşaat sürecinin yönetimi, denetimi ve güvenliği`,
havaayristirmatext:`EPC Proje , kriyojenik (sıvı ve gaz  oksijen , azot ve argon üretimi) ve VPSA(Vacuum Pressure Swing Adsorption) hava ayrıştırma tesislerin Mühendislik, Satın Alma ve montaj (EPC) modeli ile süreçlerin bütünlüğünü sağlayarak etkili bir şekilde hizmet sunmaktadır.

Mühendislik Aşaması:

•	Teknoloji seçimi
•	Mühendislik çalışmaları
•	Proje planlanması
•	Maliyet Tahminleri ve bütçe oluşumu

Satın Alma Aşaması:

•	Ana hava kompresörü, booster kompresör, turboexpander, cold box, depoplama tankları ve yardımcı sistemleri, elektrik ve otomasyon sistemlerin  tedariği
•	Tedarikçi seçimi, sözleşmelerin yapılması ve malzeme lojistiği

İnşaat Aşaması ve Devreye Alma :

•	Hava ayrıştırma tesisi inşası, montajı ve devreye alınması
•	Montaj sürecinin planlanması, yönetimi ve güvenliği
`,
sulfirikasittext:`EPC Proje , atık gazlardaki sülfür dioksit (SO2 ) oksitlenmesi sülfür trioksit (SO3) ve absorbsiyonu içeren sistemlerden geçirerek Sülfürik asit (H2SO4) elde edilmesini sağlayan sülfürik asit tesislerin Mühendislik, Satın Alma ve montaj (EPC) modeli ile süreçlerin bütünlüğünü sağlayarak etkili bir şekilde hizmet sunmaktadır. Bu kapsamda;

Mühendislik Aşaması:

•	Teknoloji seçimi
•	Mühendislik çalışmaları
•	Proje planlanması
•	Maliyet tahminleri ve bütçe oluşumu

Satın Alma Aşaması:

• Drying tower and blower section, konverter grup , absorber ve yardımcı sistemlerin, elektrik ve otomasyon sistemlerin  tedariği
•	Tedarikçi seçimi, sözleşmelerin yapılması ve malzeme lojistiği

İnşaat Aşaması ve Devreye Alma :

•	Sülfürik asit tesisin inşası, montajı ve devreye alınması
•	Montaj sürecinin planlanması, yönetimi ve güvenliği
`,
muhendisliktext:`Ön Mühendislik ve Tasarım (FEED)

EPC Proje , yatırımcıların projenin tasarımının ve uygulanabilirliğinin belirlendiği kritik kararını alabilmek için yatırımcılara proje hakkında daha fazla bilgi sağlamak amacıyla projenin uygulanabilirliği, maliyetleri ve riskleri bu aşamada daha iyi anlaşılmaktadır, bu da ileriki aşamalarda daha etkili bir proje yönetimini sağlanması hedeflemektedir. Bu kapsamda sağlanan hizmetler ;

•	Proje Tanımlama ve Gereksinim Analizi
•	Çevresel ve İzin Değerlendirmesi 
•	Risk Değerlendirmesi
•	Proses Akış Diyagramı (PFD)
•	Laboratuvar testleri 
•	Borulama ve Enstrüman Diyagramı (P&ID)
•	HAZOP, Safety Integrity Level çalışmaları,
•	Ünite Yerleşim Planı Çalışmaları(3D,2D)
•	Borulama ve Ekipman Tasarımları
•	Otomasyon Sistemleri ve Enstrumantasyon Tasarımlar
•	Elektrik Sistemi Tasarımları
•	Multidisipliner Metraj Çalışmaları (MTO)
•	Maliyet Tahmini 
•	Proje Takvimi ve Yönetim Planlarının Oluşturulması

Detay Mühendislik :

EPC Proje , Ön mühendislikte yapılan genel hesaplamaların ve tasarımların detaylı tasarım konseptleri ve parametreler üzerinde etkili bir proje yönetimini sağlanması hedeflemektedir. Bu kapsamda sağlanan hizmetler ;

Proses :

•	Proses Tasarım
•	Proses Akış Diyagramı
•	Ekipman Listesi
•	Isı ve Malzeme Dengesi
•	Borulama ve Enstrümantasyon Diyagramı

İnşaat, Yapı ve Mimarlık:

•	Yapısal Betonarme Tasarımı
•	Yapısal Çelik Tasarım, Hesaplamalar, Çizimler ve İmalat Çizimleri
•	Bina Mimari Çizimler
•	Temel Tasarım, Hesaplamalar ve Çizimler
•	Malzeme Listesi

Borulama:

•	Genel Yerleşim Planı
•	Borulama Tasarım
•	3D Modelleme 
•	Boru Stres Analizi
•	Boru Destek Tasarımı
•	Borulama Çizimleri(İzometriler)
•	Termal analiz ve izolasyon dizaynı 


Mekanik:

•	Mekanik Tasarım Temelleri
•	Mekanik Ekipman Bilgi Föyleri
•	Malzeme Listeleri (Bill of Materials - BOM)
•	Ekipman Tasarımı ve Montaj Çizimleri
•	Genel yerleşim çizimleri 
•	Termal analiz ve izolasyon dizaynı

Elektrik:

•	Elektrik Tasarım Şartnamesi
•	Tek Hat Şeması
•	MCC (Motor Control Center) projelendirilmesi
•	DCS Kontrol sistemi projelendirilmesi
•	PLC sistemleri 
•	MCC ,PLC pano yerleşim projesi
•	Scada -kontrol ve izleme sistemi 
•	Elektrik Veri föyleri
•	Aydınlatma projeleri
•	Topraklama projeleri
•	Malzeme Listesi

Enstrümantasyon ve Kontrol:

•	Enstrümantasyon ve Kontrol Tasarım Temeli
•	Enstruman / Kablo Listesi
•	Sinyal listesi 
•	Enstruman Bilgi Föyleri
•	Malzeme Listesi
`,
projeyonetimitext:`EPC Proje  , projenin yönetimi ile bir projenin başlangıcından tamamlanmasına kadar olan süreçte, taraflar arasındaki projelerin etkin bir şekilde yönetilmesini ve uygulanmasını sağlayarak proje başarısını desteklemeyi hedeflemekteyiz. Bu kapsamda;

Sözleşme Yönetimi: 

• Proje süresince taraflar arasındaki sözleşmelerin etkili bir şekilde planlanmasını, uygulanmasını, yönetilmesini, sözleşme şartlarının takibi, değişiklik yönetimi ve anlaşmazlık çözüm süreçleri içerir

Planlama Yönetimi : 

• Planlama yönetimi, proje sürecinin planlanması, kaynak tahsisi, takvim oluşturma, projenin ilerleyişinin izlenmesi, proje takviminin belirlenmesi, görevlerin sıralanması ve süreçlerin optimize edilmesi hedeflenir

Mühendislik yönetimi : 

• Proje mühendislik tasarımının planlanması, yürütülmesi ve yönetilmesini hedeflenir. Tasarım süreçlerinin koordinasyonu, teknik çözümlerin sağlanması ve mühendislik ekibimiz tarafından gerçekleştirilir

Satın Alma Yönetimi: 

• projede kullanılacak malzeme ve ekipmanların etkili bir şekilde seçilmesini, tedarik edilmesini ve yönetilmesini hedeflenir. Sözleşme müzakereleri, tedarik zinciri yönetimi ve malzeme tedariği bu sürecin bir parçası olarak sürece dahil edilir

İnşaat ve Montaj Yönetimi: 

• Saha inşaat ve montaj faaliyetlerinin planlanması, koordinasyonu, denetimi ve sahadaki işlerin etkili bir şekilde yönetilmesini hedeflenir

Kalite Yönetimi : 

• Projenin kalite standartlarına uygun olarak ilerlemesi, alite kontrol süreçlerinin planlanması, uygulanması ve izlenmesi hedeflenir

Mali Yönetim : 

• Bütçe oluşturma, maliyet tahminleri, harcamaların izlenmesi ve mali risk yönetimini içerir

Risk Yönetimi :

• Olası risklerin belirlenmesi, değerlendirilmesi ve bu risklere karşı uygun önlemlerin alınması süreçlerini içerir
`,
gubretext:`EPC proje , amonyum sülfat gübre üretimi tesislerin planlanması, granülasyon teknikleri ile ürünlerin daha etkili bir şekilde dağıtılması,tesislerin Mühendislik, Satın Alma ve montaj (EPC) modeli ile süreçlerin bütünlüğünü sağlayarak etkili bir şekilde hizmet sunmaktadır. Bu kapsamda ;

Mühendislik Aşaması:

•	Gübre üretim süreçlerinin tasarımı, optimizasyonu ve mühendislik detayları.
•	Tesisin kapasitesi, teknik gereksinimler , piyasa taleplerine göre esnek üretim kapasitesi ve çevresel etkilerin değerlendirilmesi.
•	Çevre dostu gübre üretim teknikleri

Satın Alma Aşaması:

•	Hammaddelerin, kimyasalların ve özel bileşenlerin tedariği
•	Tedarikçi seçimi, sözleşmelerin yapılması ve malzeme lojistiği

İnşaat Aşaması:

•	Gübre üretim tesisi inşası, montajı ve devreye alınması
•	Montaj sürecinin planlanması, yönetimi ve güvenliği

Çevresel Kontroller ve Emisyon Yönetimi:

•	Hava ve su kalitesini korumak için emisyon kontrol sistemleri
•	Atık yönetimi ve geri dönüşüm stratejilerin oluşturulması

Teknolojik İnovasyon ve Sürekli İyileştirme:

•	Yeni üretim teknolojilerinin entegrasyonu ve sürekli iyileştirme süreçleri
•	Sensör teknolojileri ve veri analitiği ile akıllı üretim süreçleri
•	Entegre tesis yönetimi için dijital platformların kullanımı
•	Dijitalleşme ve otomasyonun gübre üretiminde etkin kullanımı

Çevresel ve Sağlık Güvenliği Standartları:

•	Çevresel uyum ve emisyon kontrolüne ilişkin düzenleyici standartlara uyum
•	İşçi sağlığı ve güvenliği standartlarına tam uyum
`,
gaztemizlemetext:`EPC Proje, atık gazları etkili bir şekilde temizlemek ve çevresel sürdürülebilirliği artırmak bu tesislerin (EPC) modeli ile süreçlerin bütünlüğünü sağlayarak etkili bir şekilde hizmet sunmaktadır. Bu kapsamda ;

Mühendislik Aşaması:

•	Gaz temizleme ve toz tutma sistemleri tasarımı ve optimizasyon
•	Atık gazların analizi ve temizleme gereksinimlerinin belirlenmesi
•	Gaz temizleme ve toz tutma sistemi için uygun ekipmanların seçimi ve entegrasyonu
•	Tesisin elektrik altyapısı ve kontrol sistemleri tasarımı
•	Çevresel izleme ve kontrol sistemlerinin entegrasyonu

Tedarik Aşaması:

•	Yıkama kuleleri (Scrubbers), katalitik konvertörler, elektrositatik filtre sistemleri, torbalı filtre sistemi, pnömatik taşıma sistemleri, emisyon kontrol cihazları, sensörler ve diğer temizleme ekipmanlarının tedariği
•	Yüksek kaliteli malzeme ve cihazların temini
•	Malzeme taşıma ve depolama planlaması
•	Lojistik süreçlerin yönetimi

Sistem Montajı ve Devreye Alma Aşaması:

•	Bu sistemlerinin montajı ve devreye alınması
•	Montaj sürecinin planlanması, yönetimi ve güvenliği 
•	Ekipman montajı ve entegrasyonu
•	Montaj sürecinde güvenlik standartlarının sağlanması
•	Montaj kalite kontrol süreçleri
`,
altinleachtesisitext:`EPC Proje, düşük tenörlü altın cevherlerinden altını çıkarmak için kullanılan altın leach tesislerin Mühendislik, Satın Alma ve montaj (EPC) modeli ile süreçlerin bütünlüğünü sağlayarak etkili bir şekilde hizmet sunmaktadır. Bu kapsamda;

Mühendislik Aşaması:

•	Mühendislik çalışmaları
•	Proje planlanması
•	Maliyet Tahminleri ve bütçe oluşumu

Satın Alma Aşaması:

•             Kırıcılar , konveyör sistemleri, değirmenler ,karıştırıcılar , tanklar ,reaktörler ,leach tankları , adsorpsiyon kolonları, çöktürme tankları , filtre presler , geri dönüşüm sistemleri ve yardımcı sistemlerin, elektrik ve otomasyon sistemlerin  tedariği.
•	Tedarikçi seçimi, sözleşmelerin yapılması ve malzeme lojistiği.

İnşaat Aşaması ve Devreye Alma :

•	Altın leach tesisin inşası, montajı ve devreye alınması
•	Montaj sürecinin planlanması, yönetimi ve güvenliği
`,


endustriyeltesisler:`EPC Proje, Endüstriyel tesislerin mühendislik, tesisin planlanması, tasarımı, inşası, işletilmesi ve bakımı gibi  anahtar teslim hizmetleri sunmaktadır. Her proje, çevresel koşullara, enerji taleplerine ve yatırımcıların ihtiyaçlarına özgü olarak tasarlanmaktadır. 

Bu Kapsamda ;

• Tesis Planlama ve Tasarımı
• Proses Mühendisliği
• Makine ve Ekipman Mühendisliği
• Elektrik ve Otomasyon Mühendisliği

Endüstriyel tesislerde mühendislik, karmaşık sistemlerin etkili bir şekilde yönetilmesi ve sürekli iyileştirilmesi ve bu alanda, teknik bilgi ve yaratıcı çözümler sunmaktadır.`,

ENERJI:"Enerji",
ENERJItext:"Enerjinin verimli kullanımı ve çevresel sürdürülebilirlik ilkelerini benimseyerek her projede yatırımcılarımıza özel çözümler sunarak, enerji tasarrufu ve çevresel etkiyi minimize etmeyi hedeflemektedir. Tecrübeli ve uzman kadromuz, en son teknolojileri kullanarak, güvenilir ve yüksek performanslı enerji sistemleri kurmaktayız",
MADEN:"Maden",
MADENtext:"Madencilik sektöründe uzmanlaşmış bir firma olarak, yenilikçi projeler ve sürdürülebilir çözümler sunuyoruz. Tecrübeli mühendislerimiz ve uzman ekibimiz , her aşamada küresel madencilik endüstrisinin karmaşıklığına ve değişkenliğine uyum sağlayarak projenin başarılı bir şekilde tamamlanmasını sağlamak için çalışmaktadır.",
Metalurji:"Metalurji",
Metalurjitext:"Metalurji endüstrinin ihtiyaçlarına yönelik özelleştirilmiş çözümler geliştirerek, yatırımcılarımıza en üst düzeyde değer sunmayı amaçlıyoruz.  Metalurjideki en son teknolojileri ve en iyi uygulamaları kullanarak, yatırımcılarımıza rekabet avantajı sağlayan çözümler sunuyoruz.",
MaterialScienceText:"Her geçen gün değişen ve gelişen dünyamızda, Uzman ekibimizle, yenilikçi yaklaşımlarla karmaşık projeleri başarıyla yönetir , hedeflerinize ve iş süreçlerinizi optimize ederek verimliliği artırarak projelerinizin başarıyla tamamlanmasını sağlamak için çalışmaktayız.",
EndustrielTesisler:"Endüstriyel Tesisler",
EndustrielTesislertext:"Endüstriyel tesislerin tasarımından başlayarak, proje yönetimi sürecinin ve devreye alım aşamasına kadar olan tüm süreçte , deneyimli mühendislerimiz ve uzman ekiplerimiz, projenin her aşamasında başarıyla tamamlanmasını sağlıyoruz.",
hidrometalurji:"Hidrometalurji",
hidrometalurjiText:"Hidrometalurji, metal cevherlerinden metal çıkarmak için kimyasal ve fiziksel işlemleri birleştiren bir yöntemdir. Altın leach tesissinde, deneyimli mühendislerimiz hidrometalurjiyi temel alarak çevre dostu ve verimli bir metal çıkarma süreci sağlarken bu alanda yenilikçi çözümler sunmaktadır.",
muhendislikproje:"Mühendislik ve Proje Yönetimi",

Contactustoday:"Bizimle iletişime geçin",
Formoreinformation:"Daha fazla bilgi için lütfen bizimle iletişime geçin. Sorularınıza mümkün olan en kısa sürede yanıt vereceğiz. ",
hometext1:"Endüstriyel Çözümlerde",
hometext2:"Teknolojiyle",
hometext3:"İşlevselliği Buluşturuyoruz",
TamamlananProjeler:"Tamamlanan Projeler",
enerjibaslik1:"Wartsila Makina Enerji Santrali ",
enerjiicerik1:` Makine sağlayan firma : Wartsila ,Finland

Kapasite	: 16,621 MW / herbir makine için x 7 adet makine	
 
Tipi : 	doğalgaz yakıtlı 18 V50

Üretim yılı : 	2001

İşin kapsamı : EPC
`,
enerjibaslik2:"Atık ısı kazanları ",
enerjiicerik2:`Atık Isı Kazan Firması : Aalborg, Finland

Kapasite : 7 t/h ,11,5 barg/herbir kazan için + 2 ton/h ,4  barg /herbir kazan için buhar 

Adet : 7 adet +7 adet 

Tipi : doğal sikülasyonlu tek geçişli dikey tip 

İşin kapsamı: EPC
`,
enerjibaslik3:"DeSOx (Desulphurization)",
enerjiicerik3:`Sağlayıcı firma :Hamon 

Kapasite : 650.000 Nm3/h 

İşin kapsamı : EPC  
`,
enerjibaslik4:"DeNOx ( Denitrogen oxides)",
enerjiicerik4:`Sağlayıcı firma : Haldor Topsø

65000 Nm3/h x 7adet  kapasiteli

İşin kapsamı: EPC  
`,
enerjibaslik5:"Siemens 8.5 MW Buhar Türbini ve yardımcı sistemleri ",
enerjiicerik5:`İşin kapsamı :EPC `,

enerjibaslik6:"Nuh Çimento 46 MW doğalgaz yakıtlı enerji santrali ",
enerjiicerik6:`Gaz Türbini :GE LM 6000,

Atık Isı kazanı  :IEG 

İşin kapsamı: EPC
`,
enerjibaslik7:"Kömür Yakıtlı Akışkan Yataklı Kazan ,doğalgaz yakıtlı kazan ve yardımcı sistemleri ",
enerjiicerik7:`Kapasite : 140 t/h , 46barg ,460 °C buhar kapasiteli

İşin kapsamı: EPC
`,
metalurjibaslik1:"Alüminyum Döküm Fırınları",
metalurjiicerik1:`Standart İngot,T-bars,Flat ingot ,Billet 

6.000 t/y Kapasiteli Alüminyum Döküm Fırınları Thermcon/ Wagstaff 

 İşin kapsamı: EPC
`,
toztutmabaslik1:"Electrostatıc Precıpıtator(ESP) ",
toztutmaicerik1:`Electrostatıc Precıpıtator Flue gas flow 80 000 Nm3/h kapasiteli, Babcock & Wilcox 

Electrostatıc Precıpıtator Flue gas flow 80 000 Nm3/h kapasiteli, Alstom, 

Electrostatıc Precıpıtator Flue gas flow 70 000 Nm3/h kapasiteli, Alstom

Electrostatıc Precıpıtator Flue gas flow 95 000 Nm3/h kapasiteli, Elex 

İşin kapsamı: EPC
`,
enerjibaslik8:"Samsun 240 MW Doğal Gaz Yakıtlı Kombine Çevrim Elektrik Santrali",
enerjiicerik8:`Santralin temel olarak şu parçalardan oluşmaktadır:

1-	GAZ TÜRBİNLERİ ve Yardımcı Ekipmanlar (2 set), 
Marka: GE LMS 100
GAZ TÜRBİNLERİ ve Yardımcı Ekipmanlar
Marka: GE
Menşe: ABD
Kapasite: Deniz seviyesinde her biri 105 MW
Miktar: 2 adet
Tür: Doğal Gaz Yakıtlı, LMS100
Üretim Yılı: 2009

2- ISI GERİ KAZANIM BUHAR JENERATÖRLERİ (2 set),
ISI GERİ KAZANIM BUHAR JENERATÖRLERİ
Marka: Aalborg
Menşe: Finlandiya
Kapasite: LP 24 ton/saat, 4 bar / her biri
HP 120 ton/saat, 40 bar / her biri
Miktar: 2 adet
Tür: Doğal dolaşım, tek geçiş, dikey tip

3- BUHAR TÜRBİNİ (1 set)
Marka: Siemens
Menşe: Almanya
Kapasite: 40 MW

4- GAZ KOMPRESÖR İSTASYONU (1 set)

5- YÜKSEK VOLTAJLI ŞALT ODASI (3 set)

6- BOP Ekipmanları
Sıkıştırılmış hava istasyonu
C&I sistemi ve ekipmanları
LV/MV AC ve DC sistemleri
Soğutma SİSTEMLERİ

İşin kapsamı: EPC
`,
enerjibaslik9:"Eti Alüminyum Fabrikası Doğalgaz  Dönüşümleri ",
enerjiicerik9:`İşin kapsamı: EPC`,
sulfirbaslik1:"Sülfürik Asit Tesisi",
sulfirikicerik1:`120,000 Nm³/h  SO2 off-gas  
%98,5 Sülfürik Asit Üretimi : 1310 ton/gün

Baca Emisyonu - Sülfürik Asit Tesisi (her üretilen Sülfürik Asit tonu başına kg SO2 cinsinden): 2,0kg SO2/t H2SO4

İşin kapsamı: EPC
`,
amonyakbaslik1:"Amonyak depolama tankı ",
amonyakicerik1:`15.000 ton sıvı amonyak (99,8 %) depolama tankı  ,

Dizayn sıcaklığı : - 40 °C , Dizayn basıncı :- 5/+200 mbarg

Çalışma sıcaklığı : -31,2/-33 °C 

Max.boil off rate : 0,04% 

Amonyak buharı geri kazanım sistemi, kompresör sistemi, gaz yoğunlaştırma sistemi

İşin kapsamı: EPC
`,
asitbaslik1:"Asit depolama tankı ",
asiticerik1:`Kapasitesi : 7500 ton x 2 adet Sülfürikasit (98% H2SO4 ) depolama tankı 

İşin kapsamı: EPC
`,
kriyojenikbaslik1:"Kriyojenik hava ayrıştırma tesisi",
kriyojenikicerik1:`Sağlayıcı firma  : Hangzhou Hangyang 

menşei : China 

Gaz oksijen : 10 000 Nm3/h ,99,6 %O2 

Likit oksijen : 500 Nm3/h , ,99,6 %O2

Gaz nitrojen : 9500 Nm3/h , ≤10 ppm O2

Likit nitrojen : 500 Nm3/h ,  ≤10 ppm O2

Likit argon : 350 Nm3/h ,≤ 1 ppm O2,≤3 ppm N2

enstrüman havası : 6000 nm3/h , 8 barg , -60 °C dew point 

İşin kapsamı: EPC
`,
amonyumbaslik1:"Amonyum sülfat gübre tesisi",
amonyumicerik1:`Kapasitesi 800 t/day ,(NH4)2SO4 Kristal 21% azot içerikli gübre 

İşin kapsamı : EPC 
`,
metalurjibaslik2:"Anot bakır döküm çarkı",
metalurjiicerik2:`Kapasite : 100 t/h (50t/h x 2)  anot bakır döküm çarkı 

İşin kapsamı: EPC
`,
madenbaslik1:"Siirt Bakır Madeni Shaft Projesi ",
madenicerik1:`Shaft Tipi : Production Shaft

Hoisting Amacı  : Malzeme Ve İnsan Taşıma  

Hosting Sağlayacısı  : Siemag Tecberg 

Mühendislik : Nerin Engineering 

Shaft Çapı : 6,5 M

Derinliği : 661 M 

Hosting Mesafesi : 629 M 

Hoisting Kapasitesi : 1 500 000 T/Y
`,
madenbaslik2:"Kırgızistan Altın Projesi",
madenicerik2:`Besleme kapasitesi : 1 500 000 t/y

Proses tipi : Tank Leaching  Plant

Mühendislik : Amec Foster Wheeler
`,
madenbaslik3:"Adıyaman Bakır Flotasyon Tesisi",
madenicerik3:`Besleme kapasitesi: 700 000 t/y 

Bakır kazanımı : 84,5%

Bakır konsantresi : 16,5 %

Bakır konsantre kapasite: 140 000 t/y

Mühendislik : Outotec

Kapsam : EPC 
`,
metalurjibaslik3:"Eti bakır Smelting  plant ",
metalurjiicerik3:`
Mühendislik tamamlama süresi : 1 yıl

Montaj ve devreye alma süresi : 6 ay 

Mühendislik : Outotec(basic dizayn ) 

Kapsam : EPC 

Flash Smelting furnace Bakır besleme kapasitesi  : 70 t/h 

kurutucu bakır besleme kapasitesi : 100 t/h

bu proje kapsamında tamamlanan tesisler ;

•	Flash smelting soğutma sistemi

•	Bakır Konstantre stokholl

•	Dryer 

•	Bakır besleme sistemleri mixture tank , loss in weight feeding system 

•	Electrostatic Precipitator (ESP)

•	Torbalı filtreler 

•	Pnömatik taşıma sistemleri 

•	PS konverter plant 

•	Anode Casting plant

•	Sülfürik asit plant 

•	Krojenik oksijen plant 
`,
powermetertext:` KPM312 Üç Fazlı Çok Kanallı Güç Ölçer, maksimum güç tüketimi için kullanılır. 4 şube devresi güç izleme ve veri iletimi. Bu benimser DIN 35mm ray tipi kurulum ve LCD ekran ve üç fazlı elektrik parametreleri ölçümü, 2 ~ 31. harmonik analizi entegre eder ve zaman istatistikleri.KPM312 varsayılan iletişimi RS485 ile Modbus-RTU'dur. WiFi, 4G,Lora iletişimi isteğe bağlıdır. Ayrıca 4 yönlü dijital iletişimi genişletebilir.girişi ve 4 yollu röle çıkışı, 4 yollu sıcaklık ölçümü içinher devre.

Ürün Özellikleri:

•İletişim arayüz protokolü

•RS485 WiFi 4G Lora

•MODBUS-RTU, MQTT


•Güç kalitesi ölçümü

•2-31. harmonik ölçüm analizi+

•Demand vaule, Max/Min vaule istatistikleri

•Artık akım ölçümü


•4 devre için 4 dijital giriş

•Dijital giriş

•Lojik alarm girişi


•4 devre için 4 dijital çıkış

•I,U,P Çıkışı sınırlar

•İletişim uzaktan kumandası

•Mantıksal çıktı


•Kayıt fonksiyonu

•Çalışma süresini ölçme, yükleme süresi istatistikleri ve kayıt

•3 aylık aylık ortalama güç faktörü

•3 aylık çoklu tarife elektrik tüketim veri kaydı
`,
powermeterbaslik:"KPM312 Üç Fazlı Çok Kanallı Güç Ölçer"
};
export { tr };