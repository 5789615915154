const cn = {
  HOME: "首页",
  ABOUT: "关于",
  SOLUTION: "解决方案",
  PROJECTS: "项目",
  NEWS: "新闻",
  SHOPS: "商店",
  CONTACT: "联系我们",
  REQUESTAQUOTE: "联系表格",
  ADDRESS: "地址",
  Home: "主页",
  PRODUCTS: "产品",
  about1: "EPC，是由在不同工程学科中拥有超过20年经验的创始人创建的专业工程公司。 ",
  about2: "凭借经验丰富的工程师团队，我们提供从项目开始到完成的项目管理、质量和合同管理、可行性研究、基础和详细设计、制造和投产等服务，采用创新和全面的解决方案。 ",
  about3: "我们公司的力量在于我们具备根据客户需求进行灵活思考和行动的能力。",
  ABOUTAS: "关于",
  Companyoverview: "公司概况",
  OurProjects: "我们的项目",
  AllProjects: "所有项目",
  Lookingfor: "我们的产品和解决方案，想了解更多，请填写联系表格",
  OurIndustry: "我们的行业不尊重传统.",
  Itonlyrespect: "它只尊重创新",
  Latestnews: "最新消息",
  haberler1: "Fusce non faucibus lorem. Cras eu velit id diam cursus tincidunt in ut dui. Quisque quis augue placerat. ",
  haberler2: "Fusce non faucibus lorem. Cras eu velit id diam cursus tincidunt in ut dui. Quisque quis augue placerat. ",
  haberler3: "Fusce non faucibus lorem. Cras eu velit id diam cursus tincidunt in ut dui. Quisque quis augue placerat. ",
  GetinTouch: "取得联系",
  OurSolutions: "我们的解决方案",
  footerLogobuttom: `生产率和信任为基础的商业模式，我们坚定地致力于达到行业的最高标准。我们始终努力成为一个受欢迎的公司，以快速、积极和创新的方式回应不断变化的期望，始终力争卓越。在每个项目和合作中不妥协于我们的质量，我们努力最大化客户满意度。我们与合作伙伴、员工和客户建立的牢固联系是 EPC Proje 的基础`,
  SEND: "发送",
  Name: "姓名",
  Email: "电子邮件",
  Phone: "电话",
  Message: "信息",
  Sektor: "部门",
  sendedMail: "我们收到了您的信息，将尽快给您回复",
  WehelpIndustries: "我们帮助的行业",
  InovateandGrow: "创新与发展",
  Youwillbeal: "您将随时了解所有商业和金融新闻，以及",
  investmenttips: "投资技巧、市场预测、商业建议和指南。",
  Learnmore: "了解更多",
  Viewservices: "查看服务",
  MaterialScience: "材料科学与工程",
  ChemicalResearch: "能源",
  PowerandEnergy: "冶金",
  SEEMORE: "查看更多",
  ScienceandEngineering: "科学与工程",
  MechanicalEngineering: "矿业",
  PetroleumandGas: "石油和天然气",
  FactureProcess: "工业设施",
  Leader: "快速可靠的工业解决案",
  inPower: "",
  andAutomotion: "和自动化",
  Lorem1: "客户很重要，有了客户才会跟着客户。 其实它是生态的",
  Mauris: "孩子们、前厅、喝水的床",
  Welcome: "用创新的方法在未来留下您的印记",
  IndustrialBusiness: "工业业务",
  toall: "",
  youwill: "您将始终了解所有商业和金融新闻，并随时了解投资技巧、市场预测、商业建议和指南。",
  ContactUs: "联系我们",
  FactureProcess2: "工程与项目管理",
  Enerji: "能源",
  Maden: "矿业",
  Metallueloji: "冶金学",
  HavaAyristirma: "空气分离",
  Sulfurukasit: "l硫酸",
  Muhendislik: "工程",
  ProjeYonetimi: "项目管理",
  Gubre: "肥料",
  GazTemizleme: "气体清洁",
  AltinLeach: "金浸出厂",
  Industrialplant: "工业设施",

  enerjitext: `EPC 项目为能源领域的项目提供了交钥匙服务。它包括工程设计、技术要求的规定、各种系统的整合、确定项目特定材料、与供应商的谈判和采购协议、物资供应、现场施工、设施安装、项目的物理实现、投产、系统测试和性能评估。每个项目都是根据环境条件、能源需求和投资者的具体需求独特设计的
  在这个范围内
  
  工程阶段：
  
  • 利用和整合可再生能源（风能、太阳能、水力等）
  • 设计和优化以化石燃料为基础的能源生产系统（蒸汽、燃气轮机、余热锅炉、煤粉锅炉、流化床锅炉、生物质燃料锅炉和辅助设施）
  • 基于能源需求的容量规划和系统优化
  • 电网和基础设施设计
  • 能源储存系统的整合
  • 智能电网技术的使用
  • 排放控制和环境可持续性
  
  采购阶段：
  
  • 采购蒸汽和燃气轮机、余热锅炉、煤粉锅炉、流化床锅炉、生物质燃料锅炉和辅助设施、发电机、变压器、面板和其他能源生产设备
  • 供应高压线路、输电电缆和能源分配设备
  • 采购可再生能源的专业组件
  
  供应商管理和合同：
  
  • 选择适合的完整解决方案供应商
  • 合同的准备和管理
  • 材料物流和供应链管理
  
  建设阶段：
  • 电力发电设施的建设、组装和投产
  • 基础工程和环境影响管理
  • 建设过程的安全性和质量
  • 能源输电线路和基础设施的建设
  • 高压输电线路的建设和维护
  • 分配基础设施的安装
  • 能源储存设施的建设`,
  miningtext: ` EPC项目在采矿行业提供全方位服务，为项目的每个阶段提供专业知识。我们可以详细介绍EPC项目在采矿项目中提供的主要关注领域和服务：

  矿区开发：
  
  • 综合考虑地理和地质特征的工程设计。
  • 确定矿藏储量并选择最佳的采矿方法。
  • 矿业工艺测试
  • 评估环境影响并制定符合环境可持续性原则的策略。
  
  工程阶段：
  
  • 设施的详细设计和施工。
  • 有效管理材料采购、设备选择和供应链。
  • 设备运输、材料流程组织和现场运营的物流需求管理。
  
  矿石准备和富集：
  
  • 矿石的提取和加工。
  • 富集过程和获取金属浓缩物。
  
  环境伦理和可持续性：
  
  • 制定减少环境影响的策略。
  • 根据环境可持续性原则规划和实施项目。
  
  项目管理：
  
  • 有效管理项目流程。
  • 在材料采购、设备选择和供应链管理方面专业化。
  • 与供应商协商协议、管理合作关系和合同。
  
  EPC项目为采矿行业提供这些全方位服务，专注于项目的成功完成，确保符合行业标准，并提供根据环境可持续性原则规划和实施项目的服务。`,

  metallurgytext: `EPC项目以其工程、采购和建设（EPC）模式，在冶金领域提供服务，确保按照行业标准和技术创新有效管理和实施与金属生产、加工和利用相关的过程。EPC项目在冶金项目中的主要关注领域和提供的服务包括：

  工程阶段：
  
  • 冶金过程的设计和优化
  • 冶金设施的工程细节和规格
  • 金属矿石的冶炼和创建具有所需性质的合金
  • 铸造过程和成型技术
  • 金属加工和制造过程的完成
  • 通过轧制、压制等方式对原材料金属进行塑形
  • 获得最终产品和质量控制过程
  • 能源效率和可持续能源的使用
  • 项目成本的控制和预算管理
  • 排放控制和环境可持续性
  • 符合职业健康和安全标准

  采购阶段：
  
  • 确定冶金所需的原材料、设备和服务
  • 与供应商签订合同和采购材料

  建设阶段：
  
  • 实现和建设冶金设施的物理过程
  • 管理、监督和确保建设过程的安全`,

  havaayristirmatext:`空气分离工厂
  EPC项目，通过工程、采购和安装（EPC）模式，确保制氧、制氮和制氩等低温（液态和气态）和VPSA（真空压力摆动吸附）空气分离工厂的流程整合，以有效地提供服务。
  
  工程阶段:
  
  • 技术选择
  • 工程研究
  • 项目规划
  • 成本估算和预算编制
  
  采购阶段:
  
  • 主空气压缩机、增压压缩机、涡轮膨胀机、冷箱、储罐以及辅助系统、电气和自动化系统的供应
  • 供应商选择、合同签订和物料物流
  
  建设阶段和投产:
  
  • 空气分离工厂的建设、组装和投产
  • 组装过程的规划、管理和安全性
  `,
  sulfirikasittext:`
  硫酸厂：
  
  工程、采购和建设（EPC）模式用于硫酸厂，通过经过含有二氧化硫（SO2）氧化生成三氧化硫（SO3）并吸收的系统，最终生产硫酸（H2SO4），确保了过程的完整性。在这个模式下，有效地提供服务，包括：
  
   工程阶段：
  
   • 技术选择
  • 工程研究
  • 项目规划
  • 成本估算和预算形成
  
  采购阶段：
  
  • 采购干燥塔和风机部分，转化器组，吸收器以及辅助系统，电气和自动化系统的供应。
  • 供应商选择，合同谈判和物料物流
  
  建设阶段和投产：
  
  • 硫酸厂的建设，组装和投产
  • 装配过程的计划，管理和安全性
  `,
  muhendisliktext:`  前端工程与设计（FEED):

  EPC项目旨在为投资者提供有关项目设计和可行性的关键决策的更多信息，以更好地了解在此阶段项目的可行性、成本和风险。这反过来旨在促进后续阶段更有效的项目管理。在此范围内提供的服务包括：
  

  • 项目识别和需求分析
  • 环境和许可评估
  • 风险评估
  • 工艺流程图（PFD）
  • 实验室测试
  • 管道和仪表图（P&ID）
  • HAZOP，安全完整性水平研究
  • 单元布局研究（3D，2D）
  • 管道和设备设计
  • 自动化系统和仪表设计
  • 电气系统设计
  • 多学科工程量清单（MTO）
  • 成本估算
  • 制定项目进度和管理计划
  
  详细工程：
  
  EPC项目旨在基于详细的设计概念和参数实现有效的项目管理，建立在前端工程与设计期间进行的一般计算和设计的基础上。在此范围内提供的服务包括：
  
  工艺:
  
  • 工艺设计
  • 工艺流程图
  • 设备清单
  • 热量和物料平衡
  • 管道和仪表图
  
  建筑、建筑和建筑学:
  
  • 结构钢筋混凝土设计
  • 结构钢设计、计算、图纸和制造图纸
  • 建筑建筑图纸
  • 基础设计、计算和图纸
  • 材料清单
  
  管道:

  • 总体布局计划
  • 管道设计
  • 3D建模
  • 管道应力分析
  • 管道支持设计
  • 管道图纸（等轴测图）
  • 热分析和绝缘设计
  
  机械:

  • 机械设计基础
  • 机械设备数据表
  • 材料清单（BOM）
  • 设备设计和装配图纸
  • 总体布局图纸
  • 热分析和绝缘设计
  
  电气:

  • 电气设计规范
  • 单线图
  • 电机控制中心（MCC）设计
  • DCS控制系统设计
  • PLC系统
  • MCC，PLC面板布局项目
  • SCADA - 控制和监控系统
  • 电气数据表
  • 照明项目
  • 接地项目
  • 材料清单
  
  仪表与控制:

  • 仪表与控制设计基础
  • 仪表/电缆清单
  • 信号清单
  • 仪表数据表
  • 材料清单
  `,
  projeyonetimitext:`EPCM（工程、采购和施工管理）项目管理旨在通过有效管理和实施项目，从项目启动到完成的整个过程，支持项目的成功。在此背景下：

  合同管理：
  
  • 涉及在整个项目期间有效规划、执行和管理各方之间的合同。该过程包括监控合同条款、变更管理和纠纷解决流程
  
  计划管理：
  
  • 涉及计划项目过程、资源分配、创建时间表和监控项目进展。目标是确定项目时间表、优先考虑任务并优化流程
  
  工程管理：
  
  • 涉及计划、执行和管理项目工程设计。该过程包括协调设计过程、提供技术解决方案，并由我们的工程团队实施
  
  采购管理：
  
•   涉及有效选择、采购和管理项目中将使用的材料和设备。合同谈判、供应链管理和物资采购都是该过程的一部分
  
工和装配管理：
  
  • 涉及规划、协调、检查和有效管理现场施工和装配活动
  
  质量管理：
  
  • 旨在确保项目按照质量标准进行推进。该过程包括计划、实施和监控质量控制过程
  
  财务管理：
  
  • 涉及预算编制、成本估算、追踪支出和管理财务风险
  
  风险管理：
  
  • 涉及识别、评估和采取适当措施应对潜在风险
  `,
  gubretext:`EPC项目，通过工程、采购和施工（EPC）模型，为化肥生产设施硫酸铵的规划提供了有效的服务，通过颗粒化技术优化流程，以更有效地分配产品，并通过确保工艺的完整性。 在此范围内，通过颗粒化技术优化流程，以更有效地分配产品，并通过工程、采购和施工（EPC）模型确保工艺的完整性
  
  工程阶段：

  • 化肥生产过程的设计、优化和工程详细信息
  • 根据市场需求评估工厂的产能、技术要求、灵活的生产能力和环境影响
  • 环保的化肥生产技术
  
  采购阶段：
  
  • 原材料、化学品和特殊组分的供应
  • 供应商选择、合同谈判和物料物流
  
  施工阶段：
  
  • 化肥生产设施的施工、组装和投产
  • 组装过程的计划、管理和安全
  
  环境控制和排放管理：
  
  • 保持空气和水质的排放控制系统
  • 废物管理和制定回收策略
  
  技术创新和持续改进：
  
  • 新生产技术的整合和持续改进过程
  • 通过传感器技术和数据分析实现智能生产过程
  • 利用数字平台进行综合设施管理
  • 在化肥生产中有效利用数字化和自动化
  
  环境和健康安全标准：
  
  • 遵守与环境合规和排放控制有关的法规标准
  • 完全遵守职业健康和安全标准
  `,
  gaztemizlemetext:`EPC项目通过工程、采购和建设（EPC）的模式，有效地提供服务，确保这些设施的过程完整，以有效地清理废气并提高环境可持续性。在此背景下：

  工程阶段：
  
  • 气体清洁和除尘系统设计与优化
  • 对废气进行分析，确定清理要求
  • 选择和整合适用于气体清洁和除尘系统的设备
  • 设计设施的电气基础设施和控制系统
  • 整合环境监测和控制系统
  
  采购阶段：
  
  • 采购洗涤塔、催化转化器、静电过滤系统、袋式过滤系统、气动输送系统、排放控制装置、传感器和其他清洁设备
  • 采购高质量的材料和设备
  • 材料运输和储存规划
  • 物流流程管理
  
  系统安装和调试阶段：
  
  • 安装和调试这些系统
  • 计划、管理和确保安装过程的安全性
  • 设备组装和整合
  • 在安装过程中确保安全标准
  • 安装过程中的质量控制流程
  `,
  altinleachtesisitext:`
  EPC项目通过工程、采购和施工（EPC）模型，以确保从低品位金矿中提取金的金浸出厂的流程完整性，有效地提供服务。在此范围内：
  
  工程阶段：
  
  • 工程研究
  • 项目规划
  • 成本估算和预算形成
  
  采购阶段：
  
  • 提供破碎机、输送系统、磨机、搅拌机、储罐、反应器、浸出槽、吸附柱、沉淀槽、过滤压榨机、回收系统和辅助系统，以及电气和自动化系统的供应
  • 供应商选择、合同谈判和物料物流
  
  施工阶段和投产：
  
  • 金浸出厂的施工、组装和投产
  • 组装过程的规划、管理和安全性
  `,

  endustriyeltesisler:`EPC项目提供工业设施的交钥匙服务，包括工程、规划、设计、施工、运营和维护。每个项目都根据环境条件、能源需求和投资者的需求进行独特设计。在此范围内：
  
  • 设施规划和设计
  • 工艺工程
  • 机械与设备工程
  • 电气与自动化工程

  工业设施工程涉及对复杂系统的有效管理和持续改进，在这一领域提供技术专业知识和创造性解决方案
  `,
  ENERJI:"能源",
  ENERJItext:"能源的高效利用和环境可持续性原则，我们旨在为投资者提供定制化解决方案，以最大程度地减少能源消耗和环境影响。凭借经验丰富的专家团队，我们采用最新技术安装可靠且高性能的能源系统",
  MADEN:"矿业",
  MADENtext:"作为一家专业从事矿业行业的公司，我们提供创新的项目和可持续的解决方案。我们经验丰富的工程师和专家团队，致力于适应全球矿业行业的复杂性和多变性，确保项目成功完成的每一个阶段",
  Metalurji:"冶金学",
  Metalurjitext:"我们旨在开发针对冶金工业需求量身定制的解决方案，为投资者提供最大价值。利用最新的冶金技术和最佳实践，我们提供能够为投资者带来竞争优势的解决方案",
  MaterialScienceText:"在日新月异的变化和发展中，我们与专业团队一起，采用创新的方法成功管理复杂项目，努力通过优化您的目标和业务流程来提高效率，确保项目顺利完成",
  EndustrielTesisler:"工业设施",
EndustrielTesislertext:"工业设施设计至项目管理和投产的全过程中，我们经验丰富的工程师和专业团队确保每个阶段的成功完成",
hidrometalurji:"水冶金学;",
hidrometalurjiText:"水冶金学是一种将化学和物理过程结合起来从金属矿石中提取金属的方法。在金浸出厂中，我们经验丰富的工程师们基于水冶金学提供环保高效的金属提取工艺，为这一领域提供创新解决方案",
muhendislikproje:"工程和项目管理",

Contactustoday:"联系我们",
Formoreinformation:"如需了解更多信息，请联系我们。我们将尽快回复您的问题。 ",
hometext1:"",
hometext2:"",
hometext3:"我们在工业解决方案中将技术和功能结合在一起",
TamamlananProjeler:"已完成项目",
enerjibaslik1:"Wartsila 发动机和辅助设备",
enerjiicerik1:`发动机品牌: Wartsila

原产地：芬兰

容量：每台（海平面上）16,621兆瓦 x 7台发动机

类型：天然气发动机, 18 V50

生产年份：2001年

范围：EPC（工程，采购，施工）
 `,
 enerjibaslik2:"废热锅炉",
 enerjiicerik2:`废热锅炉公司: Aalborg, 芬兰

 容量：每台锅炉7吨/小时，每台锅炉11.5巴格 + 每台锅炉2吨/小时，每台锅炉4巴格蒸汽
 
 数量：7台 + 7台
 
 类型：自然循环，单程通过，垂直型
 
 范围：EPC（工程，采购，施工）
 `,
 enerjibaslik3:"脱硫 (Desulphurization)",
 enerjiicerik3:`供应商: Hamon

 范围：EPC（工程，采购，施工）

 容量：650,000标准立方米/小时
 
 范围：EPC（工程，采购，施工）
 `,
 enerjibaslik4:"脱硝(脱氮氧化物)",
 enerjiicerik4:`供应商: Haldor Topsø

 容量：65000标准立方米/小时x 7台
 
 范围：EPC（工程，采购，施工）
 `,
enerjibaslik5:" Siemens 8.5兆瓦蒸汽涡轮机及辅助系统",
 enerjiicerik5:`范围：EPC（工程，采购，施工）`,
enerjibaslik6:"Nuh Çimento 46兆瓦天然气发电厂",
enerjiicerik6:`天然气涡轮机：GE LM 6000
余热锅炉：IEG
范围：EPC（工程，采购，施工）
`,
enerjibaslik7:"燃煤流化床锅炉，天然气锅炉及辅助系统",
enerjiicerik7:`容量：140吨/小时，46巴格，460°C蒸汽

范围：EPC（工程，采购，施工）
`,
metalurjibaslik1:"铝铸造炉",
metalurjiicerik1:`标准锭、T型条、扁平锭、方坯 
6.000 吨/年容量铝铸造炉

范围：EPC（工程，采购，施工）
`,
toztutmabaslik1:"静电除尘器（ESP）",
toztutmaicerik1:`静电除尘器 烟气流量 80000 Nm³/h 容量, Babcock & Wilcox

静电除尘器 烟气流量 80000 Nm³/h 容量,, Alstom,

静电除尘器 烟气流量 70000 Nm³/h 容量,, Alstom,

静电除尘器 烟气流量 95000 Nm³/h 容量,, Elex

范围：EPC（工程，采购，施工）
`,
enerjibaslik8:"Samsun 240兆瓦天然气联合循环发电厂",
enerjiicerik8:`该发电厂主要包括：

1- 燃气轮机及辅助设备（2套），品牌：GE LMS 100

燃气轮机及辅助设备
品牌：GE
产地：美国
容量：海平面上每台105兆瓦
数量：2台
类型：天然气燃烧，LMS100
制造年份：2009年

2- 热回收蒸汽发生器（2套）

热回收蒸汽发生器
品牌：Aalborg
产地：芬兰
容量：低压每台24吨/小时，4巴
高压每台120吨/小时，40巴
数量：2台
类型：自然循环，单程通过，垂直型

3- 蒸汽涡轮机（1套）

品牌：西门子
产地：德国
容量：40兆瓦

4- 燃气压缩机站（1套）

5- 高压开关站（3套）

6- BOP设备

压缩空气站
C&I系统及设备
低/中电压交直流系统
冷却系统
范围：EPC（工程，采购，施工）
`,
enerjibaslik9:"Eti 铝厂天然气转换",
enerjiicerik9:`EPC（工程，采购，施工）`,
sulfirbaslik1:" 硫酸工厂",
sulfirikicerik1:`流量：120,000 Nm³/h，SO2废气

98.5％的硫酸产量：1310吨/天

烟囱排放 - 硫酸工厂（每吨生产的硫酸中的二氧化硫千克数）：2.0千克SO2/吨H2SO4

范围：EPC（工程，采购，施工）
`,
amonyakbaslik1:"氨储罐",
amonyakicerik1:`15,000吨液氨（99.8%）储罐，

设计温度：-40°C，设计压力：-5/+200毫巴

操作温度：-31.2/-33°C

最大汽化率：0.04%

氨蒸汽回收系统、压缩系统、气体冷凝系统

范围：EPC（工程，采购，施工）
`,
asitbaslik1:"硫酸储罐",
asiticerik1:`容量：7500吨 x 2 硫酸（98% H2SO4）储罐

范围：EPC（工程，采购，施工）
`,
kriyojenikbaslik1:"低温空分装置",
kriyojenikicerik1:`品牌：杭州杭洋

产地：中国

气态氧气：10,000 Nm3/h，99.6%O2

液态氧气：500 Nm3/h，99.6%O2

气态氮气：9,500 Nm3/h，≤10 ppm O2

液态氮气：500 Nm3/h，≤10 ppm O2

液态氩气：350 Nm3/h，≤1 ppm O2，≤3 ppm N2

仪表空气：6,000 Nm3/h，8 barg，-60°C 露点

范围：EPC（工程，采购，施工）
`,
amonyumbaslik1:" 硫酸铵肥料厂",
amonyumicerik1:`产能：800吨/天，（NH4）2SO4晶体，氮含量21%的肥料
范围：EPC（工程，采购，施工）
`,
metalurjibaslik2:"阳极铜铸造厂",
metalurjiicerik2:`产能：100吨/小时（50吨/小时 x 2），阳极铜铸造

范围：EPC（工程，采购，施工）
`,
madenbaslik1:"Siirt 铜矿竖井厂",
madenicerik1:`井型：生产井

提升目的：物料和人员乘坐

提升设备供应商：Siemag Tecberg

工程：Nerin Engineering

井直径：6.5 米

深度：661 米

提升距离：629 米

提升能力：1,500,000 吨/年
`,
madenbaslik2:"吉尔吉斯斯坦黄金项目",
madenicerik2:`进料能力：1,500,000 吨/年

工艺类型：槽浸出厂

工程：Amec Foster Wheeler
`,
madenbaslik3:"铜浮选厂",
madenicerik3:`矿石供给：700,000 吨/年

铜回收率：84.5%

铜浓缩：16.5%

铜精矿产能：140,000 吨/年

工程：Outotec

范围：EPC
`,
metalurjibaslik3:"Eti 铜冶炼厂",
metalurjiicerik3:`工程完成时间：1年

装配和调试完成时间：6个月

工程：Outotec（基础工程）

范围：EPC (设计、采购和施工)

闪速冶炼炉的铜进料能力：70吨/小时

铜精矿进料能力到干燥器：100吨/小时

在这个项目范围内完成的工厂

•	闪速冶炼炉冷却系统

•	浓缩料储存建筑，包括三叉车输送机、输送机和特殊起重机系统

•	干燥器

•	铜进料系统，混合罐，重量损失进料系统

•	静电除尘器（ESP)

•	气力输送系统 

•	PS (Peirce-Smith) 转炉厂

•	阳极铸造厂 

•	硫酸厂

•	低温制氧厂
`,
powermetertext:` KPM312 三相多通道功率计用于最大4 个支路的功率监控和数据传输。它采用DIN 35mm 导轨式安装和 LCD 显示屏，集成了三相电参数测量、2~31 次谐波分析和时间统计功能。和时间统计功能。KPM312 默认通信方式为 RS485 的 Modbus-RTU。可选配 WiFi 和 4G、Lora 通信可选。它还可扩展 4 路数字4 路数字输入和 4 路继电器输出，每个电路有 4 路温度测量。每个电路的 4 路温度测量。

产品特点

•通信接口协议

•RS485 WiFi 4G Lora
•modbus-rtu, mqtt


•电能质量测量

•2-31次谐波测量分析+
•需求电流、最大/最小电流统计
•剩余电流测量


•4 路数字输入

•数字输入
•逻辑报警输入


•用于 4 个电路的 4 个数字输出端

•I、U、P 极限输出
•通讯远程控制
•逻辑输出


•记录功能

•仪表工作时间、负载时间静态和记录
•3 个月的月平均功率因数
•3 个月多费率用电数据记录`,
powermeterbaslik:"KPM312 三相多通道功率计"

};
export { cn };